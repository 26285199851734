import { storyblokInit, apiPlugin } from "@storyblok/react";
 import '../styles/globals.css';
 import Head from 'next/head';
 import '../node_modules/highlight.js/styles/lioshi.css';
 
storyblokInit({
  accessToken: "LH572xM4PYMMGZiMYCMIvQtt",
  use: [apiPlugin]
});

export default function MyApp({ Component, pageProps }) {
    return <Component {...pageProps} />
  }